.menu-social {
  padding: 0;
  list-style-type: none;

  li {
    display: inline-block;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-repeat: no-repeat;

    &.deezer {
      background-image: url("../assets/images/deezer.svg");
    }

    &.spotify {
      background-image: url("../assets/images/spotify.svg");
    }

    &.googleplaymusic {
      background-image: url("../assets/images/googleplaymusic.svg");
    }
  }
}
