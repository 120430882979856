.media-item {
  position: relative;

  .title-wrapper {
    text-align: center;
  }

  &__title {
    text-align: center;
    margin: 0 auto 40px;
  }

  &__image,
  &__video,
  &__embed {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  &__video {
    video {
      width: 100%;
    }
  }

  &__poster {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: opacity 0.3s, visibility 0.3s;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__toggle {
    position: absolute;
    z-index: 2;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
    appearance: none;
    background-color: transparent;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: opacity 0.3s, visibility 0.3s;

    svg {
      width: 100%;
      height: auto;
      g {
        fill: #fff;
      }
    }

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}
