// THIS FILE SHOULD HOLD ALL THE GLOBAL STYLES FOR THE WEBSITE'S GUI (specifics to project - not to be mistaken with normalize)
//----- Table of contents:
//----- 0. Globals
//----- 1. Titles
//----- 2. Links/anchors
//----- 3. Lists
//----- 4. GUI elements



//---------- 0. Globals ----------
html {
  font-size: 62.5%; // = 10px
  line-height: 1.5;
  box-sizing: border-box;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

body {
  //font-size: 1.6rem;
  @include font-gui;
  padding-top: 120px; // compensate fixed header height in order for the content to always remain visible
  font-size: 1.6rem;
  line-height: 1.3125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
[tabindex="-1"]:focus {
  outline: 0 !important;
}

//---------- 1. Titles ----------
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  color: inherit;
}

p {
  margin-top: 0;
}

//---------- 2. Links/anchors ----------
a {
  @include link-colors($gui-black, $gui-blue, $gui-blue, $gui-blue,  $gui-blue);
}
