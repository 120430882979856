// IMPORT AND DECLARE FONT MIXINS + UTILS HERE
//@import url('https://fonts.googleapis.com/css?family=Heebo|Open+Sans+Condensed:700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:700');

@mixin font-titles {
    font-family: 'Open Sans Condensed', 'Arial Black', sans-serif;
    font-style: normal;
    font-weight: 700;
}

@mixin font-gui {
    //font-family: 'Heebo', Arial, sans-serif;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
}