// Container external gap.
$gap: 32px;
$gap--tablet: $gap;
$gap--desktop: $gap--tablet;

// Overwrite BULMA initial vars (if needed)

$tablet: 704px + (2 * $gap);
$desktop: 960px + (2 * $gap--desktop);

$susy: (
  'columns': susy-repeat(4),
  'gutters': 0.66,
  'spread': 'narrow',
  'container-spread': 'wide',
);

$medium: (
  'columns': susy-repeat(4),
  'gutters': 0.24,
  'spread': 'narrow',
  'container-spread': 'wide',
  'min-width': $tablet
);

$large: (
  'columns': susy-repeat(12),
  'gutters': 6.42,
  'spread': 'narrow',
  'container-spread': 'wide',
  'min-width': $desktop
);
