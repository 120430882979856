.audio-player {
    color: $gui-white;
    @include gradient;

    .container-inner {
        display: flex;
        flex-direction: column-reverse;
        @include tablet {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__image-wrapper {
        position: relative;
        width: 100%;
        @include tablet {
            width: span(5 of 10);
        }

        img {
            width: 100%;
        }

        audio {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 180px;
            @include tablet {
                width: 80%;
            }
        }
    }

    &__contents {
        @include tablet {
            width: span(4 of 10);
        }
    }

}