// Base colors
$gui-white: #fff;
$gui-black: #000;
// gui colors
$gui-grey-d: #58595B;
$gui-grey-l: #D7D7D7;
$gui-blue: #0098DB;
$gui-pink: #CD0B72;

@mixin gradient {
    background: linear-gradient(to right, $gui-blue 0%, $gui-pink 120%);
}
