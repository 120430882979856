// REM setup for fonts -- to be used as following: @include rem(1.5, 1.8)  ----> original font-size = 15px, original line-height = 18px
// Simply divide the px value by ten -- if line-height value isn't specified, it will be 1.5 times the font-size value
@mixin rem(
  $sizeValue: 1.6,
  $lineValue: (
    $sizeValue * 1.5
  )
) {
  font-size: $sizeValue + rem;
  line-height: $lineValue + rem;
}

// Utils.
@mixin font-smoothing($value: true) {
  @if $value == true {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    font-smooth: auto;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin link-colors(
  $normal,
  $hover: false,
  $active: false,
  $visited: false,
  $focus: false
) {
  color: $normal;

  @if $visited {
    &:visited {
      color: $visited;
    }
  }

  @if $focus {
    &:focus {
      color: $focus;
    }
  }

  @if $hover {
    &:hover {
      color: $hover;
    }
  }

  @if $active {
    &:active {
      color: $active;
    }
  }
}

@mixin link-two-colors($normal, $hover) {
  @include link-colors($normal, $hover, $normal, $normal, $hover);
}

@mixin reset-box-model {
  display: block;
  margin: 0;
  padding: 0;
}

@mixin no-bullet {
  list-style-image: none;
  list-style-type: none;
  margin-left: 0;
}

@mixin no-bullets {
  list-style: none;

  li {
    @include no-bullet;
  }
}

@mixin clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin css-triangle($color, $direction, $size: 6px) {
  height: 0;
  width: 0;
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  }
}

@mixin breakpoint_from_string($breakpoint) {
  @if ($breakpoint == "tablet") {
    @include tablet {
      @content;
    }
  }
  @if ($breakpoint == "desktop") {
    @include desktop {
      @content;
    }
  }
}

@mixin mobile-only {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @include susy-at($medium) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @include susy-use($medium) {
      @content;
    }
  }
}

@mixin desktop {
  @include susy-at($large) {
    @content;
  }
}
