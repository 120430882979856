.swiper-container {}

.swiper-wrapper {}

.swiper-slide {}

.swiper-pagination {}
.swiper-scrollbar {}

.swiper-pagination-bullet {
  //cursor: pointer;
}


.swiper-button-prev,
.swiper-button-next {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-image: none;
  background-color: $gui-pink;
  margin-top: 0;
  transform: translateY(-17px);

  @include tablet {
    width: 45px;
    height: 45px;
    transform: translateY(-22px);
  }

}

.swiper-button-prev {
  left: -45px;
  @include tablet {
    left: -95px;
    //left: 0;
  }
}
.swiper-button-next {
  right: -45px;
  @include tablet {
    right: -95px;
    //right: 0;
  }
  svg {
    transform: rotate(180deg);
  }
}