.text {

  &__title {

  }

  &__contents {
    display: flex;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__text {
    &-col {
      width: 100%;
      @include tablet {
        width: span(5 of 10);
      }
    }
  }

}
