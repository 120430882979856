// Visibility
.hidden-mobile {
  display: none !important;
  @include mobile-only {
    display: block !important;
  }
}

.hidden-tablet {
  display: none !important;
  @include tablet-only {
    display: block !important;
  }
}

.hidden-desktop {
  display: block !important;
  @include desktop {
    display: none !important;
  }
}
