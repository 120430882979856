select:focus {
    outline: none;
}

/* hide default down arrow in IE10*/
select::-ms-expand {
    display: none;
}

.custom-select {
    position: relative;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;

    span {
        position: absolute;
        top: 50%;
        right: 0;
        @include css-triangle($gui-black, down, 6px);
        transform: translate(-10px, -50%);
    }
}

.custom-select {
    &,
    select {
        width: 240px;
    }
}

.custom-select select {
    height: 34px;
    padding: 5px 0 5px 5px;
    background: transparent;
    border: none;
    /*hide default down arrow in webkit */
    -webkit-appearance: none;
}

@-moz-document url-prefix() {
    .custom-select select {
        width: 110%;
    }
}