.module {
    padding: 60px 0;

    &.m-embed,
    &.m-image {
        padding: 0;
    }

    &.m-video {
        &:first-child {
            padding: 0;
        }
    }

}