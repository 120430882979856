.site-footer {
    @include gradient;
    color: $gui-white;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    h4 {
        position: relative;
        @include font-titles;
        font-size: 1.6rem;
        line-height: 1.125em;
        margin-bottom: 40px;
        &:after {
            @include pseudo;
            bottom: -15px;
            left: 0;
            width: 35px;
            height: 1px;
            background-color: $gui-white;
        }
    }

    ul {
        padding: 0;
        list-style-type: none;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__social,
    &__nav {
        width: 100%;
        margin-bottom: 20px;
        @include tablet {
            margin-bottom: 0;
            width: span(3 of 12);
        }
    }

    //&__nav {}

    &__copyright {
        width: 100%;
        align-self: flex-end;
        @include font-titles;
        i {
            margin-left: 5px;
        }
        @include tablet {
            text-align: right;
            width: span(6 of 12);
        }
    }

}
