.container {
  margin-left: $gap;
  margin-right: $gap;

  @include susy-at($medium) {
    margin-left: $gap--tablet;
    margin-right: $gap--tablet;
  }
  @include susy-at($large) {
    margin-left: auto;
    margin-right: auto;
    width: $desktop - (2 * $gap--desktop);
  }
}

.container-inner {
  position: relative;
  width: span(10 of 12);
  margin: 0 auto;
  //@include tablet {
  //  position: initial;
  //}
}