.multiple-images {

  &__row {
    @include tablet {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &--first {
        margin-bottom: 20px;
        align-items: flex-end;

        .image {
          width: calc(55% - 10px);

          &:last-child {
            width: calc(45% - 10px);
          }
        }
      }

      &--last {

        .image {
          width: calc(45% - 10px);

          &:last-child {
            width: calc(55% - 10px);
          }
        }
      }
    }

    .image {
      margin-bottom: 20px;

      @include tablet {
        margin-bottom: 0;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}
