.clearfix {
  &:before,
  &:after {
    display: table;
    width: 100%;
    clear: both;
  }
}

.hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}


// General text utilities.
.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.t-bold {
  font-weight: bold;
}

.t-upper {
  text-transform: uppercase;
}
