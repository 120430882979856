.section-title {
    display: inline-block;
    margin-bottom: 40px;
    @include font-titles;
    font-size: 3rem;
    line-height: 1.25em;
    text-transform: uppercase;

    &.m-colored {
        //background: linear-gradient(to right, $gui-blue 0%, $gui-pink 120%);
        @include gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}