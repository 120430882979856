.site-header {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 120px;
    top: 0;
    left: 0;
    background-color: $gui-white;
    opacity: 1;
    transition: opacity 0.2s;

    .m-unpinned & {
        opacity: 0;
        transition: opacity 0.2s;
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    &__logo {
        float: left;
        max-width: 120px;
        img {
            width: 100%;
        }
        @include tablet {
            max-width: 247px;
        }
    }

    &__locales {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
            display: inline-block;
            @include font-gui;

            &:first-child {
                position: relative;
                margin-right: 15px;
                &:after {
                    @include pseudo(block, absolute, '–');
                    color: $gui-grey-l;
                    top: 0;
                    right: -15px;
                }
            }

            a {
                color: $gui-grey-l;
                text-transform: uppercase;
                text-decoration: none;


            }

            &.current-lang {
                a {
                    color: $gui-grey-d;
                }
            }

        }
    }

}

.label-very_high_school {
    margin-left: 5px;
    float: left;
    display: none;

    span {
        display: block;
        @include font-titles;
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 1em;
        margin-bottom: 3px;
        background: linear-gradient(to right, $gui-blue 0%, $gui-pink 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @include tablet {
        display: inline-block;
    }
}
